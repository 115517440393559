<template>
  <v-container class="dashboard">
    <v-row>
      <v-col sm="6" cols="12" md="6" lg="3">
        <router-link tag="a" to="/participant-management">
          <material-stats-card
            color="#156a59"
            icon="mdi-briefcase-search-outline"
            title="Participant management"
            value
          />
        </router-link>
      </v-col>
      <v-col sm="6" cols="12" md="6" lg="3">
        <router-link tag="a" to="/roster">
          <material-stats-card
            color="#156a59"
            icon="mdi-timetable"
            title="Roster"
            value
            sub-icon="mdi-tag"
            sub-text
          />
        </router-link>
      </v-col>
      <v-col sm="6" cols="12" md="6" lg="3" v-if=this.isAdmin>
        <router-link tag="a" to="/invoicing-billing">
          <material-stats-card
            color="#156a59"
            icon="mdi-currency-usd"
            title="Invoicing and billing"
            value
            sub-icon="mdi-tag"
            sub-text
          />
        </router-link>
      </v-col>
      <v-col sm="6" cols="12" md="6" lg="3">
        <router-link tag="a" to="/feedback">
          <material-stats-card
            color="#156a59"
            icon="mdi-clipboard-outline"
            title="Feedback"
            value
            sub-icon="mdi-tag"
            sub-text
          />
        </router-link>
      </v-col>
      <v-col sm="6" cols="12" md="6" lg="3" v-if=this.isAdmin>
        <router-link tag="a" to="/admin">
          <material-stats-card
            color="#156a59"
            icon="mdi-account-supervisor-circle"
            title="Admin Dashboard"
            value
          />
        </router-link>
      </v-col>
    </v-row>
    <v-row>
      <v-col sm="12" cols="12" md="12" lg="12">
        <v-card>
          <v-card-text>
            <div style="height: 100%">
              <canvas id="myChart" style="height: 60vh"></canvas>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <div class="container">
      <Button @click="previous()" v-if="perPage !== 0" class="button prev"
        >Previous</Button
      >
      <Button
        @click="next()"
        v-if="perPage !== Math.round(reportData.length / 10) * 10"
        class="button next"
        >Next</Button
      >
    </div>
  </v-container>
</template>

<style>
.v-card--material-stats:hover {
  border-top: 4px solid #156a59;
}
</style>
<script>
var Chart = require("chart.js");
import * as caseService from "../services/caseService";
import * as budgetItemService from "../services/budgetItemService";
//import * as reportService from "../services/reportService";
import * as reportService1 from "../services/reportService1";

export default {
  data() {
    return {
      slider: 0,
      perPage: 0,
      reportData: [],
      limitReportData: [],
      cases: [],
      budgetItems: [],
      isAdmin: false,
      tabs: 0,
      myChart: "",
      list: {
        0: false,
        1: false,
        2: false,
      },
    };
  },

  mounted() {
    if (
      this.user.roles.indexOf("System Administrator") > -1 ||
      this.user.roles.indexOf("Organisation Administrator") > -1
    ) {
      this.isAdmin = true;
    }
    this.init();
  },
  computed: {
    currentOrganisation() {
      return this.$store.getters.currentOrganisation;
    },
    user() {
      return this.$store.getters.user;
    },
  },
  methods: {
    async init() {
      console.log(this.currentOrganisation.id);
      caseService.activeCases(this.currentOrganisation.id).then((result) => {
        let cases = [];
        result.docs.map((doc) => {
          const participants = doc.data();
          participants.id = doc.id;
          cases.push(participants);

          this.cases = cases;
        });
        console.log(this.cases.length);

          
            this.cases.forEach((c) => {

                budgetItemService
                    .list(this.currentOrganisation.id, c.id)
                    .then((result) => {
                      let budgetItems = [];
                      let reportObj = {};
                      let reportData = [];
                      result.docs.map((doc) => {
                        const item = doc.data();
                        item.id = doc.id;
                        budgetItems.push(item);
                      });

                    this.budgetItems = budgetItems;
                    if (this.budgetItems.length != 0) {
                    for (let p = 0; p < this.budgetItems.length; p++) {
                      console.log(this.budgetItems[p].length);
                      reportObj["budget"] = this.budgetItems[p].budget;
                      reportObj["budgetItem"] = this.budgetItems[p].title;
                      reportObj["budgetItemId"] = this.budgetItems[p].id;
                      reportObj["budgetItemLongName"] =
                        c.participantDetails.firstname +
                        " " +
                        c.participantDetails.lastname +
                    " " +
                    "-" +
                    this.budgetItems[p].title;
                      reportObj["firstname"] = c.participantDetails.firstname;
                      reportObj["lastname"] = c.participantDetails.lastname;
                      reportObj["organisation"] = this.currentOrganisation.name;
                      reportObj["participantId"] = c.id;
                      if (this.budgetItems[p].spent !== undefined) {
                        reportObj["totalSpent"] = this.budgetItems[p].spent;
                      } else {
                        reportObj["totalSpent"] = 0;
                      }

                  reportData.push(reportObj);
                  reportObj = {};
                }
               // console.log(reportData);
              }
              this.deleteReport(reportData);
              this.addReport(reportData);
            });
          });
      });

      await reportService1
        .getBudgetItemsTotalsReport1(this.currentOrganisation.id)
        .then((doc) => {
          let uid = "";

          let tempReportData = doc.data().rows;

          this.cases.forEach((e) => {
            if (
              e.signatures != undefined &&
              e.signatures.authorizedUsers != undefined &&
              e.signatures.authorizedUsers.length != 0
            ) {
              e.signatures.authorizedUsers.forEach((i) => {
                uid = i.id;
                console.log(uid + " =>" + this.user.id);
                if (uid == this.user.id || uid == "" || this.isAdmin) {
                  this.reportData = tempReportData;
                  //console.log(e.id);
                } else {
                  let tempArray = [];
                  console.log(e.id);
                  tempReportData.forEach((r) => {
                    if (r.participantId != e.id) {
                      tempArray.push(r);
                    }
                  });
                  this.reportData = tempArray;
                }
              });
            }
          });
          if (this.isAdmin || uid == "") {
            this.reportData = tempReportData;
          }
         // this.reportData=doc.data().rows;
          this.reportData.sort(function (a, b) {
            return a.lastname.localeCompare(b.lastname);
          });
        });

      console.log(this.reportData.length);

      for (let i = 0; i < 10; i++) {
        if (i < this.reportData.length) this.limitReportData[i] = this.reportData[i];
      }
      this.limitReportData.sort(function (a, b) {
        return a.lastname.localeCompare(b.lastname);
      });
      console.log(this.limitReportData);

      this.showChart(this.limitReportData);
    },

    deleteReport(reportData) {
     // console.log("ahoy");
     //let counter=0;
      reportService1
        .getBudgetItemsTotalsReport1(this.currentOrganisation.id)
        .then((doc) => {
          const rows = doc.data().rows;

          for (let i = 0; i < rows.length; i++) {
            for (let j = 0; j < reportData.length; j++) {
              if (rows[i].budgetItemId == reportData[j].budgetItemId) {
                if (
                  rows[i].budget != reportData[j].budget &&
                  rows[i].totalSpent == reportData[j].totalSpent
                ) {
                  console.log(rows[i]);
                  reportService1
                    .deleteReport(this.currentOrganisation.id, rows[i])
                    .then((result) => {
                      console.log("report deleted", result);
                    })
                    .catch((err) => {
                      console.log(err);
                    });
                }
                if (
                  rows[i].budget == reportData[j].budget &&
                  rows[i].totalSpent != reportData[j].totalSpent
                ) {
                  //console.log(rows[i]);
                  reportService1
                    .deleteReport(this.currentOrganisation.id, rows[i])
                    .then((result) => {
                      console.log("report deleted", result);
                    })
                    .catch((err) => {
                      console.log(err);
                    });
                }
                if (
                  rows[i].budget != reportData[j].budget &&
                  rows[i].totalSpent != reportData[j].totalSpent
                ) {
                  console.log(rows[i]);
                  reportService1
                    .deleteReport(this.currentOrganisation.id, rows[i])
                    .then((result) => {
                      console.log("report deleted", result);
                    })
                    .catch((err) => {
                      console.log(err);
                    });
                }
                // if (
                //   rows[i].budget == reportData[j].budget &&
                //   rows[i].totalSpent == reportData[j].totalSpent
                // ) {
                //   counter++;
                  
                //   if(counter==2)
                //   {
                //     console.log(counter);
                //     console.log(rows[i]);
                //     //counter=0;
                //   reportService1
                //     .deleteReport(this.currentOrganisation.id, rows[i])
                //     .then((result) => {
                //       console.log("report deleted", result);
                //       counter=0;
                //     })
                //     .catch((err) => {
                //       console.log(err);
                //     });
                //   }
                // }
              }
            }
          }
        });
    },
    addReport(reportData) {
     // console.log("hello");
      for (let x = 0; x < reportData.length; x++) {
        reportService1
          .addReport(this.currentOrganisation.id, reportData[x])
          .then((result) => {
            console.log("report added", result);
          })
          .catch((err) => {
            console.log(reportData[x]);
            console.log(err);
          });
      }
    },

    previous() {
      // console.log(this.perPage);
      if (this.myChart != undefined || this.myChart != null) {
        this.myChart.destroy();
      }

      if (this.slider == 0) {
        this.perPage = this.perPage - 10;

        for (let i = 0; i < 10; i++) {
          let j = i + this.perPage;
          if (j < this.reportData.length) this.limitReportData[i] = this.reportData[j];
        }
        this.limitReportData.sort(function (a, b) {
          return a.lastname.localeCompare(b.lastname);
        });
        // console.log(this.limitReportData);
        this.showChart(this.limitReportData);
      }
    },
    next() {
      if (this.myChart != undefined || this.myChart != null) {
        this.myChart.destroy();
      }

      this.slider = 1;
      if (this.slider == 1) {
        this.perPage = this.perPage + 10;

        if (this.perPage < Math.round(this.reportData.length / 10) * 10) {
          for (let i = 0; i < 10; i++) {
            let j = i + this.perPage;
            if (j < this.reportData.length) {
              this.limitReportData[i] = this.reportData[j];
            }
            console.log(this.perPage);
          }
        } else {
          let k = this.reportData.length - Math.round(this.reportData.length / 10) * 10;
          for (let i = 0; i < k; i++) {
            this.limitReportData[i] = this.reportData[i + this.perPage];
            console.log(this.perPage);
          }
        }

        this.limitReportData.sort(function (a, b) {
          return a.lastname.localeCompare(b.lastname);
        });

        this.showChart(this.limitReportData);
        this.slider = 0;
      }
    },
    complete(index) {
      this.list[index] = !this.list[index];
    },

    showChart(chartData) {
      const ctx = document.getElementById("myChart").getContext("2d");
      Chart.defaults.global.defaultFontSize = 13;
      this.myChart = new Chart(ctx, {
        type: "horizontalBar",

        data: {
          labels: chartData.map((x) => {
            return x.budgetItemLongName;
          }),
          datasets: [
            {
              label: "Budget",

              data: chartData.map((x) => {
                return x.budget;
              }),
              backgroundColor: "rgba(25, 128, 107, 0.8)",
              borderColor: "rgba(25, 128, 107, 1)",
              borderWidth: 1,
            },
            {
              label: "Spent",
              data: chartData.map((x) => {
                return x.totalSpent;
              }),

              backgroundColor: "rgba(211, 211, 211, 0.8 )",
              borderColor: "rgba(211, 211, 211,1)",
              borderWidth: 1,
            },
          ],
        },
        options: {
          maintainAspectRatio: false,

          scales: {
            scaleLabel: { display: true },
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                  maxTicksLimit: 10,
                },
              },
            ],
            xAxes: [
              {
                ticks: {
                  autoSkip: true,
                  maxTicksLimit: 10,

                  callback: function (value) {
                    return "$" + value;
                  },
                },
              },
            ],
          },
        },
      });
      this.myChart.canvas.parentNode.style.height = "100%";
    },
  },
};
</script>
<style>
.dashboard .col {
  padding: 6px;
}

.button {
  background-color: #156a59;
  border-radius: 5px;
  border: 1px solid #cccccc;
  color: white;
  padding: 8px 16px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
}
.button:hover {
  background-color: #d3d3d3;
  color: black;
}
.next {
  float: right !important;
}
</style>
